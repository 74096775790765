import { createGlobalStyle } from 'styled-components';

const HeaderGlobalStyles = createGlobalStyle`
  .scroll-down .header-bar{
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .header-bar{
    transform: none;
  }

  .scrolled .header-wrapper{
    background: ${({ theme }) => theme.colors.Primary};
    transition: background 1s ease;
  }

  .darkHeader .header-wrapper{
    background: ${({ theme }) => theme.colors.Primary};
  }
`;

export default HeaderGlobalStyles;
