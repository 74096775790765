import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import loadable from '@loadable/component';
import { useLocation } from 'react-router-dom';
import SvgComponent from '../../../../src/common/SvgComponent';
import Button from '../../../../src/common/Button';
import Container from '../../../../src/common/FlexBox/Container';
import HeaderTopBar from '../../../../src/common/HeaderTopLinks';
import HeaderLoadable from '../../../../src/common/HeaderLoadable';
import Share from '../../../../src/common/Share';
import headerSprite from '../../../../src/assets/header-sprite.svg';
import DropDown from '../../../../src/common/DropDown';
import { LOGIN_URL, POST_AD_URL } from '../../../../src/helpers/constants';
import HeaderGlobalStyles from './HeaderGlobalStyles';
import config from '../../../../config';
const logoData = require('./logoData.json');
const DownloadWidget = loadable(() => import('../../../../src/common/DownloadWidget'));

const { STATIC_FILE_PATH } = config;

const UserAccount = [
  {
    label: 'My Account',
    href: 'https://www.quikr.com/MyQuikr',
    svg: 'ic_profile_1'
  },
  {
    label: 'My Ads',
    href: 'https://www.quikr.com/MyQuikr?action=activeads',
    svg: 'ic_my_ads'
  },
  {
    label: 'Get Instant Leads',
    href: 'https://www.quikr.com/instant-leads',
    svg: 'ic_instant_leads'
  },
  {
    label: 'Orders and Payments',
    href: 'https://www.quikr.com/Escrow/MyOrders?user=buyer&tabId=-1&tabName=Escrow',
    svg: 'ic_orders_payments'
  },
  {
    label: 'Chats',
    href: 'https://www.quikr.com/MyQuikr?action=mychats',
    svg: 'ic_chat'
  }
];

const HeaderWrap = styled.header`
  background: ${({ theme }) => theme.colors.Primary};
  transition: background 0.2s ease;
  @media screen and (min-width: 768px) {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  }
`;

const HeaderMain = styled.div`
  padding: 15px 12px;
  @media screen and (min-width: 768px) {
    padding: 10px 0;
  }
`;
const MenuIcon = styled.a`
  margin-right: 10px;
  svg {
    fill: #666;
    width: 20px;
    height: 20px;
    display: block;
  }
`;
const Qlink = styled.a`
  width: 26px;
  height: 26px;
  background: #fff;
  svg {
    width: 18px;
    height: 18px;
    fill: ${({ theme }) => theme.colors.Secondary};
  }
  @media screen and (min-width: 768px) {
    width: inherit;
    height: inherit;
    background: transparent;
    border-radius: inherit;
    svg {
      display: none;
    }
  }
`;
const Img = styled.img`
  margin-right: 20px;
  ${({ qLogo }) => css`
    height: 28px;
  `};
`;
const Postad = styled(Button)`
  height: 40px;
  margin-left: auto;
`;
const RightLinks = styled.div`
  margin-left: auto;
`;

const Mlinks = styled.a`
  height: 26px;
  width: 26px;
  margin-right: 16px;
  padding: 3px;
  svg {
    fill: #fff;
    height: 20px;
    width: 20px;
  }
  ${({ type }) =>
    type === 'back' &&
    css`
      padding: 0;
      width: 20px;
      height: 20px;
      svg {
        width: 15px;
        height: 15px;
      }
    `}
`;

const LoginLink = styled(Button)`
  margin-right: 10px;
  height: 40px;
  margin-left: auto;
  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
`;
const HeaderFix = styled.div`
  z-index: 9;
  transition: transform 0.3s linear;
  position: fixed;
  @media screen and (max-width: 768px) {
    position: sticky;
  }
`;

const HeaderPixel = styled.span`
  width: 1px;
  height: 1px;
  top: 100px;
  visibility: hidden;
`;

const redirectLogin = e => {
  e.preventDefault();
  e.stopPropagation();
  window.location = `${LOGIN_URL}?redirect=${encodeURIComponent(window.location.href)}`;
};

const HeaderComponent = ({
  cityEnable,
  showSearch,
  citySlectionEnabale,
  isMobile,
  isAndroid,
  downloadApp,
  pageType,
  toggleMenu,
  loggedIn,
  userObj,
  city,
  showShare,
  MsiteHamburger,
  useGa,
  handleSearch,
  handleAutoComplete,
  autoSuggestions,
  onChange,
  searchedText,
  hideHamburger,
  ...rest
}) => {
  const pixelRef = useRef(null);
  let { category } = { ...rest };

  if (!category) category = 'horizontal';
  useEffect(() => {
    const body = document.body;
    if (isMobile) {
      const observer = new IntersectionObserver(([entry]) => {
        !entry.isIntersecting ? body.classList.add('scrolled') : body.classList.remove('scrolled');
      });
      observer.observe(pixelRef.current);

      return () => observer.disconnect();
    }
  }, [isMobile, pixelRef]);

  const handleBack = () => {
    window.history.back();
  };

  const { user: { name: userName } = {}, contact: { name } = {} } = userObj || {};
  const displayName = loggedIn ? userName || name || 'Hi Quikr User' : 'Login/Register';
  const { pathname } = useLocation() || {};
  const showPostAdBtn = !/\/post-ad|\/edit-ad/.test(pathname);
  const altCategory = category === 'cars' && /\/bikes/.test(pathname) ? 'bikes' : category;
  return (
    <>
      <HeaderPixel ref={pixelRef} className="header-pixel pA"></HeaderPixel>
      {!downloadApp && isMobile && isAndroid && (
        <DownloadWidget
          category={category}
          subCategory={'NA'}
          pageType={pageType}
          city={city}
          useGa={useGa}
        />
      )}
      <HeaderFix className="header-bar l0 t0 w100">
        <HeaderGlobalStyles />
        {!isMobile && <HeaderTopBar />}
        <HeaderWrap className={isMobile && 'header-wrapper'}>
          <Container>
            <HeaderMain className={`dFA fwW ${isMobile ? 'fwW' : ''}`}>
              {!isMobile && !hideHamburger && (
                <MenuIcon onClick={toggleMenu}>
                  <SvgComponent svgSprite={headerSprite} id="ic_hamburger" />
                </MenuIcon>
              )}
              {isMobile && !MsiteHamburger && (
                <Mlinks className="dFA jcC" type="back" onClick={handleBack}>
                  <SvgComponent svgSprite={headerSprite} id="ic_back" />
                </Mlinks>
              )}
              {isMobile && MsiteHamburger && (
                <Mlinks className="dFA jcC" onClick={toggleMenu}>
                  <SvgComponent svgSprite={headerSprite} id="ic_hamburger" />
                </Mlinks>
              )}
              <Qlink
                className="dFA jcC br50"
                title={logoData[altCategory].alt}
                href={logoData[altCategory].href}
              >
                <SvgComponent svgSprite={headerSprite} id="ic_quikr_logo" />
                <span className="link-text-hidden">Quikr</span>
                {!isMobile && (
                  <Img
                    alt={logoData[altCategory].alt}
                    className="qLogo"
                    src={`/${STATIC_FILE_PATH}/${logoData[altCategory].src}`}
                  />
                )}
              </Qlink>
              {isMobile && (
                <RightLinks className="dF">
                  {!loggedIn ? (
                    <Mlinks className="dFA jcC" href={LOGIN_URL} onClick={redirectLogin}>
                      <SvgComponent svgSprite={headerSprite} id="ic_account"></SvgComponent>
                    </Mlinks>
                  ) : (
                    <Mlinks className="dFA">
                      <DropDown
                        dropInfo={{
                          tag: 'svg',
                          svgId: 'ic_account',
                          name: ''
                        }}
                        options={true}
                        optionsData={UserAccount}
                        svgSprite={headerSprite}
                        isMobile={isMobile}
                      />
                    </Mlinks>
                  )}
                  {showShare && <Share />}
                </RightLinks>
              )}
              <HeaderLoadable
                isMobile={isMobile}
                headerSprite={headerSprite}
                city={city}
                showSearch={showSearch}
                handleSearch={handleSearch}
                handleAutoComplete={handleAutoComplete}
                autoSuggestions={autoSuggestions}
                onChange={onChange}
                searchedText={searchedText}
              />
              {!isMobile && (
                <RightLinks className="dF">
                  {!loggedIn ? (
                    <LoginLink
                      className="dFA fwM cP"
                      btnType="login"
                      href={LOGIN_URL}
                      primary={false}
                      type="button"
                      onClick={redirectLogin}
                    >
                      <SvgComponent svgSprite={headerSprite} id="ic_profile_2"></SvgComponent>
                      {displayName}
                    </LoginLink>
                  ) : (
                    <DropDown
                      dropInfo={{
                        tag: 'svg',
                        svgId: 'ic_profile_2',
                        name: displayName,
                        dropDown: true
                      }}
                      options={true}
                      optionsData={UserAccount}
                      svgSprite={headerSprite}
                    />
                  )}
                  {showPostAdBtn && (
                    <Postad
                      className="dFA fwM"
                      btnType="postAd"
                      href={POST_AD_URL}
                      primary={false}
                      size="lg"
                      type="button"
                    >
                      Post Free Ad
                    </Postad>
                  )}
                </RightLinks>
              )}
            </HeaderMain>
          </Container>
        </HeaderWrap>
      </HeaderFix>
    </>
  );
};

HeaderComponent.propTypes = {
  children: PropTypes.any,
  cityEnable: PropTypes.bool,
  showSearch: PropTypes.bool,
  citySlectionEnabale: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAndroid: PropTypes.bool,
  downloadApp: PropTypes.bool,
  pageType: PropTypes.string,
  category: PropTypes.string,
  toggleMenu: PropTypes.func,
  loggedIn: PropTypes.bool,
  userObj: PropTypes.object,
  city: PropTypes.object,
  showShare: PropTypes.bool,
  MsiteHamburger: PropTypes.bool,
  useGa: PropTypes.func,
  handleSearch: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  autoSuggestions: PropTypes.array,
  onChange: PropTypes.func,
  searchedText: PropTypes.string,
  hideHamburger: PropTypes.bool
};

HeaderComponent.defaultProps = {
  showShare: true,
  hideHamburger: false
};

export default HeaderComponent;
